<template>
  <!--app首页内容管理-->
  <div class="columnHomepage">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="display: flex;justify-content: space-between;overflow: hidden;">
        <div  class="menu" >
          <a-menu
              style="width: 100%;"
              mode="inline"
              v-model="menu"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="item.code"
                @click="leftChange(item.code)"
            >
              {{item.title}}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="center">
          <a-table size="middle" :scroll="{ x: 1500}" :columns="columns" style="margin-bottom: 10px;min-height: 542px" :data-source="tableData"  rowKey="id"   :pagination="false"
          >
            <!--        序号-->
            <template slot="index" slot-scope="text,row,index">
              {{index + ((pagination.current - 1) * pagination.pageSize) + 1}}
            </template>
            <span slot="sort" slot-scope="text,item,index">
          <a style="margin-right: 10px" v-if="index != 0" @click="move('up',item.id)">上移</a>
          <a @click="move('down',item.id)">下移</a>
        </span>
        <span slot="operate" slot-scope="text,item">
          <a style="margin-left: 10px" @click="removedBtn(item.id)"> 下架</a>
        </span>
          </a-table>
          <div   style="display: flex;justify-content: space-between;right: 62px;bottom: 3.5rem;z-index: 3">
                    <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                        pagination.current
                      }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          showQuickJumper
                          showSizeChanger
                          @showSizeChange="showSizeChange"
                          @change="pageChange" />
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {delAppContent, getAppContent, postAppContentMove} from "@/service/homePageFeatures_api";
import {getColumnVodsList} from "@/service/MedicalConference_y";
import moment from "moment/moment";

export default {
  name: "columnHomepage",
  data(){
    return{
      tableData: [],
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '序号',
          fixed: 'left',
          width: 70,
          align: 'center',
          scopedSlots: {customRender: 'index'}
        },
        {
          title: '标题',
          width: '40%',
          dataIndex: 'title'
        },
        {
          title: '来源',
          width: '20%',
          dataIndex: 'contentType',
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'created_time',
        },
        {
          title: '排序',
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '操作',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'operate' },
        },],
      InfoTable:[],
      menu:[],
      typeInfo:[],
      typeIndex:0,
    }},
  created() {
    this.$store.dispatch('setManageHeaderTitle',"App首页内容管理")
  },
  mounted() {
    this.getMenu() // 获取左侧菜单
  },
  methods:{
    async getMenu () { // 获取左侧菜单
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.typeInfo = response.data
        this.menu[0] = response.data[0].code
        await this.getAPPInfo()
      }else {
        this.$message.warning(response.message)
      }
    },
    async getAPPInfo(type){
      if (type) {this.pagination.current = 1}
      this.spinning=true
      const info = await getAppContent(this.menu[0], parseInt(this.pagination.current) - 1, this.pagination.pageSize)
      if (info.code===0){
        let data = {}
        let tdata = []
        info.data.rows.forEach(item => {
          if (!item.childData) {
            return
          }
          if (item.childData.content) { // 判断有 content 的
            data = {
              id: item.id,
              content_type: item.content_type,
              sub_content_type: item.sub_content_type,
              created_time: moment(item.created_time).format('YYYY-MM-DD HH:mm'),
              title: item.childData.content.title
            }
          }else { // 判断无 content 的
            data = {
              id: item.id,
              content_type: item.content_type,
              sub_content_type: item.sub_content_type,
              created_time: moment(item.created_time).format('YYYY-MM-DD HH:mm'),
              title: item.childData.title,
            }
          }
          switch (item.sub_content_type) {
            case 'TEXT':
              data.contentType = '社区-文字内容'
              break;
            case 'IMAGE':
              data.contentType = '社区-图片内容'
              break;
            case 'LINK':
              data.contentType = '社区-外链'
              break;
            case 'VIDEO':
              data.contentType = '社区-视频内容'
              break;
            case 'DOCUMENT':
              data.contentType = '社区-指南'
              break;
            case 'MAGAZINE':
              data.contentType = '社区-杂志'
              break;
            case 'RECRUITMENT':
              data.contentType = '社区-招聘'
              break;
            case 'MIX_SET_TOPIC':
              data.contentType = '点播-混合专题'
              break;
            case 'VOD_SINGLE':
              data.contentType = '点播-单视频'
              break;
            case 'ARTICLE_SINGLE':
              data.contentType = '文章'
              break;
            case 'SERIES':
              data.contentType = '直播-小会-系列'
              break;
            case 'SINGLE':
              data.contentType = '直播-小会-普通'
              break;
            case 'CONVENTION':
              data.contentType = '直播-医学会议（多会场）'
              break;
            default:
              data.contentType = '未知code值，请联系管理员'
          }
          tdata.push(data)
        })
        this.tableData = tdata
        this.pagination.total = info.data.count
        this.spinning = false
      }else {
        this.$message.error("获取错误，请联系管理员~"+info.message);
      }
    },
    leftChange(code){
      this.menu[0] = code
      this.getAPPInfo(true)
    },
    //下架
    async removedBtn(id){
      const info= await delAppContent(id)
      if (info.code === 0){
        await this.getAPPInfo(this.pagination.current)
        this.$message.success('设置成功')
      }
    },
    //分页跳转
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      this.getAPPInfo()
    },
    showSizeChange (pageNum,pageSize) { // 分页器-改变每页显示条数
      console.log(pageSize)
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      this.getAPPInfo()
    },
    //排序
    async move(type,id){
      const data = {
        code:this.typeInfo[this.typeIndex].code,
        moveUpDown : type
      }
      const info = await postAppContentMove(id,data)
      if (info.code===0){
        this.$message.success('设置成功')
        await this.getAPPInfo(this.pagination.current)
      }else {
        this.$message.error("设置失败,请重试"+result.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none;
}
.columnHomepage{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu{
    background-color: white;
    width: 14%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .center{
    background-color: white;
    width: 85%;
    margin-bottom: -1000px;
    padding: 15px 15px 1000px;
    ::v-deep .ant-table-title{
      padding: 0;
    }
  }
}
</style>
